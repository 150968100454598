
import { Component, Vue } from 'vue-property-decorator'
import { Page } from '@/types/common'
import { PageType } from '@/types/message'
import { MessageType } from '@/utils/enum'

@Component({
  name: 'MessageTemplate'
})
export default class extends Vue {
  private searchInfo= {
    templateTitle: ''
  }

  private tableData = {
    loading: false,
    tr: [
      {
        label: '模板标题',
        prop: 'templateTitle',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '模板说明',
        prop: 'templateExplain',
        minWidth: '150',
        showOverflowTooltip: true
      },
      {
        label: '消息内容',
        prop: 'templateMsg',
        minWidth: '200',
        showOverflowTooltip: true
      },
      {
        label: '状态',
        prop: 'templateState',
        minWidth: '80'
      }
    ],
    data: [{}]
  }

  private messageType = MessageType

  private total = 0
  private page = 1
  private size = 10
  created () {
    this.getData()
  }

  getData () {
    this.tableData.loading = true
    this.$axios.get<Page<PageType>>(this.$apis.message.templatePage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    })
      .then((res) => {
        this.tableData.data = res.list || []
        this.total = res.total || 0
        this.tableData.loading = false
      })
      .catch((err) => {
        this.tableData.loading = false
        console.log(err)
      })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  onDisable (id: string) {
    this.$axios.post(this.$apis.message.templateDisable, {
      id: id
    })
      .then(() => {
        this.$message({
          message: '操作成功',
          type: 'success'
        })
        this.getData()
      })
      .catch((err) => {
        this.$message.error(err)
      })
  }
}
